import { faCheck, faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import {
  CheckCircle,
  MoreVert,
  RadioButtonUncheckedOutlined,
} from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import { Grid, IconButton, Stack } from '@mui/material';
import React, { useRef, useCallback, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useNavigate } from 'react-router-dom';

import { TimesheetItem } from '../../TimesheetList';
import { getTimeSheetStatus, JobStatus, TimeSheetStatus } from '../../util';
import ProfileModal from '../ProfileModal';
import ProfileModalRevamp from '../ProfileModal/ProfileModalRevamp';
import {
  HiredChip,
  WorkerPayRate,
  showNeedsApprovalChip,
} from '../workerUtils';

import InlineTimesheetView from './InlineTimesheetView';
import OptionsModal from './OptionsModal';

import {
  BlockIconWithBackground,
  EditTimesheetIcon,
  GravyTrained,
  HeartFillIcon,
  HeartIcon,
} from '@/assets/icons';
import AddRatingIcon from '@/assets/icons/AddRatingIcon';
import ApprovedByIcon from '@/assets/icons/ApprovedByIcon';
import CommentCounterIcon from '@/assets/icons/CommentCounterIcon';
import Avatar from '@/components/Avatar';
import IconicButton from '@/components/IconicButton';
import RatingField from '@/components/RatingField';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import styled from '@/styles';
import { timesheetColors } from '@/styles/colors';
import {
  GetJobQuery,
  RelationshipKindEnum,
  TimesheetStatusEnum,
} from '@/types/graphql';
import { containsGravyCertification, workerName } from '@/util/worker';
import PermissionComponent from '@/routes/PermissionComponent';

const CardHeading = styled('h6', {
  fontSize: '14px',
  fontWeight: '600',
  color: '#262626',
  margin: '0',
  wordBreak: 'break-word',
});

const CardCriteria = styled('span', {
  fontSize: '12px',
  fontWeight: '600',
  color: '#262626',
});

const CardCriteriaTotal = styled('span', {
  fontSize: '12px',
  fontWeight: '400',
  color: '#7A7A7A',
});

const TotalText = styled('p', {
  fontSize: '13px',
  fontWeight: '400',
  color: '#01483D',
  span: {
    fontWeight: '700',
  },
});

const ApprovalAlert = styled('p', {
  fontSize: '12px',
  fontWeight: '400',
  color: '#fff',
  background: '#ECA300',
  padding: '4px 8px',
  borderRadius: '8px',
});

const Counter = styled('span', {
  color: '#FFF',
  fontSize: '11px',
  fontWeight: '500',
  position: 'absolute',
  textAlign: 'center',
});

interface Props {
  workerInfo: any;
  disableEdit?: boolean;
  workerStatusChip?: React.ReactNode;
  totalTime?: string | number;
  menuItems: React.ReactNode[] | undefined;
  handleEditTimesheet?: () => void;
  handleApproveTimesheet?: () => void;
  disableCheckbox: boolean;
  isSelecting?: boolean;
  setIsSelecting?: (isSelect: boolean) => void;
  setCheckboxIds?: (ids: any) => void;
  checkboxIds: string[];
  timesheet?: TimesheetItem;
  handleAddComment?: () => void;
  isClientAdmin: boolean;
  job: GetJobQuery['job'];
  jobStatus: JobStatus;
  relationshipStatus: RelationshipKindEnum | null;
  createWorkerRelationship: (
    reason: string,
    comments: string,
    kind: RelationshipKindEnum,
  ) => void;
  deleteWorkerRelationship: (kind: RelationshipKindEnum) => void;
}

const WorkerMobileView = ({
  workerInfo,
  disableEdit,
  totalTime = '-',
  menuItems = [],
  handleEditTimesheet,
  handleApproveTimesheet,
  disableCheckbox,
  isSelecting,
  setIsSelecting,
  checkboxIds,
  setCheckboxIds,
  timesheet,
  handleAddComment,
  isClientAdmin,
  workerStatusChip,
  job,
  jobStatus,
  relationshipStatus,
  createWorkerRelationship,
  deleteWorkerRelationship,
}: Props) => {
  const name = workerName(workerInfo);
  const MENU_ITEMS = [...menuItems];

  const showRating = useFeatureValue(FEATURE_TOGGLE.WorkerRating, false);

  const [showOption, hideOptionModal] = useModal(
    () => (
      <OptionsModal
        hideModal={hideOptionModal}
        menuItems={MENU_ITEMS}
        title={name}
      />
    ),
    [MENU_ITEMS, name],
  );
  const [showProfModal, hideProfModal] = useModal(() => (
    <ProfileModal ProfileInfo={workerInfo} hideModal={hideProfModal} />
  ));

  const [showProfileRevampModal, hideProfileRevampModal] = useModal(
    () => (
      <ProfileModalRevamp
        ProfileInfo={workerInfo}
        hideModal={hideProfileRevampModal}
        isClientAdmin={isClientAdmin}
        relationshipStatus={relationshipStatus}
        createWorkerRelationship={createWorkerRelationship}
        deleteWorkerRelationship={deleteWorkerRelationship}
      />
    ),
    [workerInfo, isClientAdmin, relationshipStatus],
  );

  const navigate = useNavigate();
  const handleWorkerClick = () => {
    if (isClientAdmin && showRating) {
      showProfileRevampModal();
    }
    if (!isClientAdmin) {
      showProfileRevampModal();
    }
  };

  const holdTimer = useRef<any | null>(null);
  const checked = checkboxIds.includes(workerInfo.workerId);

  const startHold = useCallback(() => {
    if (disableCheckbox || isSelecting) return;

    holdTimer.current = setTimeout(() => {
      setCheckboxIds?.((ids) => [...ids, workerInfo.workerId]);
      setIsSelecting?.(true);
    }, 1000);
  }, [disableCheckbox, isSelecting]);

  const cancelHold = useCallback(() => {
    clearTimeout(holdTimer.current);
    holdTimer.current = null;
  }, []);

  const handleClick = () => {
    if (isSelecting && !disableCheckbox) {
      if (checked) {
        setCheckboxIds?.((ids) => ids.filter((i) => i !== workerInfo.workerId));
      } else {
        setCheckboxIds?.((ids) => [...ids, workerInfo.workerId]);
      }
    }
  };

  const isCertified = containsGravyCertification(workerInfo.activeCertificates);

  return (
    <>
      <Stack
        style={{
          opacity: 1,
          width: '100%',
        }}
        onTouchEnd={cancelHold}
        onTouchStart={startHold}
      >
        <Stack
          alignItems={isSelecting ? 'center' : 'flex-start'}
          borderBottom={'1px solid rgba(121, 192, 167, 0.90)'}
          borderRadius="12px 13px 0px 0px"
          flexDirection="row"
          gap={isSelecting ? '24px' : '6px'}
          padding="13px"
          style={{
            background: 'linear-gradient(270deg, #EFFFED -78.65%, #FFF 75.74%)',
          }}
        >
          <Stack>
            <Grid item xs={3} onClick={handleClick}>
              {isSelecting && !disableCheckbox ? (
                <div id="checkbox" style={{ left: 7, top: 3 }}>
                  {checked ? (
                    <CheckCircle htmlColor={timesheetColors.selected} />
                  ) : (
                    <RadioButtonUncheckedOutlined
                      htmlColor={timesheetColors.unSelected}
                    />
                  )}
                </div>
              ) : (
                <div style={{ position: 'relative' }}>
                  <Avatar
                    border={isCertified}
                    rounded={true}
                    size="m"
                    src={workerInfo.avatarUrl}
                  />
                  {isCertified && (
                    <GravyTrained
                      sx={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        width: '15px',
                        height: '15px',
                      }}
                    />
                  )}
                </div>
              )}
            </Grid>
          </Stack>
          <Stack
            alignItems={'flex-start'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            width={'100%'}
          >
            <Stack flexDirection={'column'} gap={'2px'}>
              <Stack
                flexDirection={'row'}
                gap={'8px'}
                style={{ paddingRight: '5px' }}
              >
                <CardHeading
                  onClick={
                    !isSelecting && showRating ? handleWorkerClick : undefined
                  }
                >
                  {name}
                </CardHeading>
                {relationshipStatus === RelationshipKindEnum.BLOCKED ? (
                  <BlockIconWithBackground />
                ) : (
                  <PermissionComponent id="toggle-favorite-btn" showAsReadOnly>
                    <IconicButton
                      a11yLabel="Toggle Favorite"
                      appearance="clear"
                      transparentOnHover={true}
                      customIcon={
                        relationshipStatus ===
                        RelationshipKindEnum.FAVORITED ? (
                          <HeartFillIcon
                            style={{
                              cursor: 'pointer',
                            }}
                          />
                        ) : (
                          <HeartIcon
                            style={{
                              cursor: 'pointer',
                            }}
                          />
                        )
                      }
                      data-testid="toggle-favorite"
                      icon={faCircle}
                      id="toggle-favorite-btn"
                      size="sm"
                      type="button"
                      onClick={() => {
                        if (
                          relationshipStatus === RelationshipKindEnum.FAVORITED
                        ) {
                          deleteWorkerRelationship(
                            RelationshipKindEnum.FAVORITED,
                          );
                        } else {
                          createWorkerRelationship(
                            '',
                            '',
                            RelationshipKindEnum.FAVORITED,
                          );
                        }
                      }}
                    />
                  </PermissionComponent>
                )}
              </Stack>
              <Stack alignItems={'center'} flexDirection={'row'} gap={'8px'}>
                {
                  <Stack alignItems={'center'} direction={'row'} gap={'2px'}>
                    <StarIcon
                      id="star-icon"
                      sx={{
                        color: '#faaf00',
                        width: '16px',
                        height: '16px',
                      }}
                    />
                    <CardCriteria>
                      {workerInfo.averageRating
                        ? workerInfo.averageRating.toFixed(1)
                        : '0.0'}
                    </CardCriteria>

                    <CardCriteriaTotal>{`(${workerInfo.jobsRatingCount})`}</CardCriteriaTotal>
                  </Stack>
                }

                <WorkerPayRate
                  clientAdmin={isClientAdmin}
                  job={job}
                  timesheet={timesheet}
                  workerId={workerInfo.id}
                />
              </Stack>
              {/* Note: Rating End */}
            </Stack>

            <Stack alignItems={'flex-start'} flexDirection={'row'}>
              {workerStatusChip}
              <PermissionComponent id="timesheet-options-menu">
                <IconButton
                  disabled={!MENU_ITEMS.length}
                  style={{ padding: '1px' }}
                  onClick={showOption}
                >
                  <MoreVert />
                </IconButton>
              </PermissionComponent>
            </Stack>
          </Stack>
        </Stack>
        <WorkerTimesheet
          disableEdit={disableEdit}
          handleAddComment={handleAddComment}
          handleApproveTimesheet={handleApproveTimesheet}
          handleEditTimesheet={handleEditTimesheet}
          isClientAdmin={isClientAdmin}
          isSelecting={isSelecting}
          job={job}
          jobStatus={jobStatus}
          phoneOnly={true}
          timesheet={timesheet}
          totalTime={totalTime}
          workerInfo={workerInfo}
          workerStatusChip={workerStatusChip}
        />
      </Stack>
    </>
  );
};

interface WorkerTimesheetProps {
  workerInfo: any;
  disableEdit?: boolean;
  workerStatusChip?: React.ReactNode;
  totalTime?: string | number;
  handleEditTimesheet?: () => void;
  handleApproveTimesheet?: () => void;
  isSelecting?: boolean;
  setIsSelecting?: (isSelect: boolean) => void;
  timesheet?: TimesheetItem;
  handleAddComment?: () => void;
  isClientAdmin: boolean;
  job: GetJobQuery['job'];
  jobStatus: JobStatus;
  phoneOnly: boolean;
}

export const WorkerTimesheet = ({
  isSelecting,
  disableEdit,
  timesheet,
  isClientAdmin,
  jobStatus,
  workerStatusChip,
  job,
  workerInfo,
  totalTime,
  handleAddComment,
  handleApproveTimesheet,
  handleEditTimesheet,
  phoneOnly,
}: WorkerTimesheetProps) => {
  const [inlineEditing, setInlineEditing] = useState(false);
  const tsStatus = getTimeSheetStatus(timesheet);

  const allowInlineEditing = () => {
    return (
      !isSelecting &&
      !disableEdit &&
      (!timesheet ||
        (timesheet &&
          timesheet.status !== TimesheetStatusEnum.APPROVED &&
          timesheet.status !== TimesheetStatusEnum.REJECTED))
    );
  };

  const hasComments = () => {
    if (
      timesheet &&
      timesheet.timesheetComments &&
      timesheet.timesheetComments.length
    ) {
      if (isClientAdmin) {
        return (
          timesheet.timesheetComments.filter(
            (c) => c.actorType === 'CustomerAdmin',
          ).length > 0
        );
      }
      return true;
    }
    return false;
  };

  const showReviewButton = () => {
    return (
      (jobStatus !== JobStatus.UPCOMING ||
        (jobStatus === JobStatus.UPCOMING &&
          typeof workerStatusChip === typeof HiredChip)) &&
      jobStatus !== JobStatus.CANCELLED &&
      (!timesheet ||
        (timesheet &&
          timesheet.status !== TimesheetStatusEnum.APPROVED &&
          timesheet.status !== TimesheetStatusEnum.REJECTED))
    );
  };

  return (
    <Stack
      direction={phoneOnly ? 'column' : 'row'}
      padding={phoneOnly ? '13px' : '0px'}
      style={
        phoneOnly
          ? {}
          : {
              justifyContent: 'flex-end',
              alignItems: 'center',
              flexWrap: 'wrap',
              columnGap: '20px',
              rowGap: '10px',
            }
      }
    >
      <InlineTimesheetView
        allowEdit={allowInlineEditing()}
        job={job}
        phoneOnly={phoneOnly}
        setInlineEditing={setInlineEditing}
        timesheet={timesheet}
        totalTime={!phoneOnly ? totalTime : undefined}
        workerInfo={workerInfo}
      />
      {phoneOnly && (
        <Stack
          alignItems={'center'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          padding={'10px 0 3px 9px'}
        >
          <TotalText>
            Total: <span>{totalTime !== '-' ? `${totalTime} hours` : '-'}</span>
          </TotalText>
          {showNeedsApprovalChip(workerInfo, timesheet, job) ? (
            <ApprovalAlert>Needs Approval</ApprovalAlert>
          ) : tsStatus === TimeSheetStatus.APPROVED ? (
            <Stack alignItems={'center'} flexDirection={'row'} gap={'4px'}>
              <ApprovedByIcon
                style={{
                  width: '16px',
                  height: '14px',
                }}
              />{' '}
              {timesheet?.updatedBy ? (
                <>
                  {`${
                    timesheet.updatedBy.firstName
                  } ${timesheet.updatedBy.lastName?.substring(0, 1)}.`}
                </>
              ) : timesheet?.updatedByType === 'GravyWorkSystem' ? (
                'System Approved'
              ) : (
                '-'
              )}
            </Stack>
          ) : null}
        </Stack>
      )}

      <Stack
        alignItems={phoneOnly ? 'normal' : 'flex-end'}
        gap={'4px'}
        style={{ marginTop: phoneOnly ? 5 : 0 }}
      >
        <Stack flexDirection={'row'} gap={'8px'}>
          {timesheet?.timesheetComments?.length || timesheet?.rating ? (
            <div
              id="rating-section"
              style={{ cursor: 'pointer', width: '50%' }}
              onClick={() => timesheet && handleAddComment?.()}
            >
              <Stack
                className={timesheet ? '' : 'rating-stack rating-disabled'}
                direction={'row'}
                spacing={2}
                sx={{
                  border: '1px solid #44A735',
                  borderRadius: '4px',
                  padding: '6.8px 12px',
                  justifyContent: 'center',
                }}
              >
                <RatingField
                  initialRating={timesheet?.rating || 0}
                  readOnly={true}
                  showNumberLabel={false}
                  starSize="16"
                />
                {hasComments() ? (
                  <Stack
                    gap={'0'}
                    marginLeft={'6px !important'}
                    position={'relative'}
                    style={{ justifyContent: 'center', alignItems: 'center' }}
                  >
                    <CommentCounterIcon
                      style={{
                        fontSize: '18px',
                        margin: '0px',
                      }}
                    />
                    <Counter>{timesheet?.timesheetComments?.length}</Counter>
                  </Stack>
                ) : (
                  <div className="icon-add-rating">
                    <svg
                      fill="none"
                      height="17"
                      viewBox="0 0 19 17"
                      width="19"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.99996 0.5C13.5833 0.5 17.3333 3.48333 17.3333 7.16667C17.3333 7.65 17.2666 8.11667 17.15 8.56667C16.6583 8.31667 16.125 8.13333 15.5666 8.05C15.6333 7.76667 15.6666 7.46667 15.6666 7.16667C15.6666 4.40833 12.6833 2.16667 8.99996 2.16667C5.31663 2.16667 2.33329 4.40833 2.33329 7.16667C2.33329 9.925 5.31663 12.1667 8.99996 12.1667L9.90829 12.125L9.83329 13L9.89996 13.7917L8.99996 13.8333C8.00829 13.8333 7.01663 13.6917 6.05829 13.4167C4.53329 14.6667 2.64163 15.4083 0.666626 15.5C2.60829 13.5583 2.95829 12.25 2.95829 11.75C1.54996 10.6417 0.708293 8.95833 0.666626 7.16667C0.666626 3.48333 4.41663 0.5 8.99996 0.5ZM14 9.66667H15.6666V12.1667H18.1666V13.8333H15.6666V16.3333H14V13.8333H11.5V12.1667H14V9.66667Z"
                        fill="#A1A1A1"
                      />
                    </svg>
                  </div>
                )}
              </Stack>
            </div>
          ) : (
            <>
              <IconicButton
                a11yLabel="Rate Worker"
                appearance="outline"
                customIcon={
                  <>
                    <AddRatingIcon
                      style={{
                        height: '15px',
                        width: '15px',
                        color:
                          !timesheet || disableEdit || inlineEditing
                            ? '#97A0AF'
                            : '#44A735',
                      }}
                    />{' '}
                  </>
                }
                disabled={!timesheet || disableEdit || inlineEditing}
                icon={faCircle}
                iconTheme={'default'}
                id="btn_more_filters"
                size={'full'}
                style={{
                  padding: '8px 25px',
                  width: '50%',
                }}
                text="Rate Worker"
                onClick={() => handleAddComment && handleAddComment()}
              />
            </>
          )}
          {showReviewButton() ? (
            <>
              <IconicButton
                a11yLabel={'Approve'}
                customIcon={
                  <>
                    <EditTimesheetIcon
                      style={{
                        color:
                          disableEdit ||
                          inlineEditing ||
                          (isClientAdmin && !timesheet)
                            ? '#97A0AF'
                            : 'white',
                        height: '15px',
                        width: '15px',
                      }}
                    />{' '}
                  </>
                }
                disabled={
                  disableEdit || inlineEditing || (isClientAdmin && !timesheet)
                }
                icon={faCircle}
                iconTheme={'default'}
                id="btn_more_filters"
                size={'full'}
                style={{
                  padding: '8px 18px',
                  width: '50%',
                }}
                text={'Approve'}
                onClick={() => {
                  if (isClientAdmin) {
                    handleApproveTimesheet && handleApproveTimesheet();
                  } else {
                    handleEditTimesheet && handleEditTimesheet();
                  }
                }}
              />
            </>
          ) : null}
          {timesheet?.status === TimesheetStatusEnum.APPROVED && (
            <>
              <IconicButton
                a11yLabel="Approved"
                appearanceMod={'black'}
                disabled={disableEdit}
                icon={faCheck}
                iconTheme={'default'}
                id="btn_more_filters"
                size={'full'}
                style={{
                  padding: '8px 18px',
                  width: '50%',
                }}
                text="Approved"
                onClick={() => {
                  if (!isClientAdmin && handleEditTimesheet) {
                    handleEditTimesheet();
                  }
                }}
              />
            </>
          )}
          {timesheet?.status === TimesheetStatusEnum.REJECTED && (
            <>
              <IconicButton
                a11yLabel="Rejected"
                appearanceMod={'danger'}
                disabled={disableEdit}
                icon={faTimes}
                iconTheme={'default'}
                id="btn_more_filters"
                size={'full'}
                style={{
                  padding: '8px 18px',
                  width: '50%',
                }}
                text="Rejected"
                onClick={() => {
                  if (!isClientAdmin && handleEditTimesheet) {
                    handleEditTimesheet();
                  }
                }}
              />
            </>
          )}
        </Stack>
        {!phoneOnly && timesheet?.status === TimesheetStatusEnum.APPROVED ? (
          <Stack
            alignItems={'center'}
            flexDirection={'row'}
            gap={'4px'}
            justifyContent={'flex-end'}
          >
            <ApprovedByIcon
              style={{
                width: '16px',
                height: '14px',
              }}
            />{' '}
            {timesheet?.updatedBy ? (
              <>
                {`${
                  timesheet.updatedBy.firstName
                } ${timesheet.updatedBy.lastName?.substring(0, 1)}.`}
              </>
            ) : timesheet?.updatedByType === 'GravyWorkSystem' ? (
              'System Approved'
            ) : (
              '-'
            )}
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default WorkerMobileView;
