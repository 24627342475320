import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faBriefcase,
  faEnvelope,
  faMobileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useModal } from 'react-modal-hook';

import useChangeAvatarModal from '../../useChangeAvatarModal';

import AddressSection from './AddressSection';
import BackgroundCheck from './BackgroundCheck';
import CertificateSection from './CertificatesSection';
import EmployeeDocuments from './EmployeeDocuments';
import I9Form from './I9Form';
import LanguageSection from './LanguageSection';
import OnboardingStatus from './OnboardingStatus';
import SkillsSection from './SkillsSection';
import TaxSection from './TaxSection';
import UpdateProfileModal from './UpdateProfileModal';

import { CheckIdIcon, GravyTrained } from '@/assets/icons';
import Avatar from '@/components/Avatar';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Body } from '@/components/Typography';
import Config from '@/config';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import Icon from '@/elements/Icon';
import { GenericActionOrList } from '@/types';
import {
  GenderEnum,
  GetWorkerQuery,
  SmartphoneTypeEnum,
  TaxTypeEnum,
} from '@/types/graphql';
import { EXTERNAL_URL } from '@/util/constants';
import { containsGravyCertification } from '@/util/worker';

type Props = {
  worker: GetWorkerQuery['worker'];
  currentAdminIsCustomerAdmin: boolean;
};

const workerGender = {
  [GenderEnum.MALE]: 'Male',
  [GenderEnum.FEMALE]: 'Female',
  [GenderEnum.NON_BINARY]: 'Non-Binary',
  [GenderEnum.UNSPECIFIED]: 'Unspecified',
};

const smartphoneType = {
  NONE: 'Smartphone not provided',
  [SmartphoneTypeEnum.ANDROID]: 'Android',
  [SmartphoneTypeEnum.IOS]: 'Apple',
  [SmartphoneTypeEnum.OTHER]: 'Other',
};

const getConditionToShowElegibleForW2EmploymentLabel = (
  worker: Props['worker'],
) => worker.taxType === TaxTypeEnum.TAX_1099 && worker.hasSsn;

const ProfileCard = ({ worker, currentAdminIsCustomerAdmin }: Props) => {
  const showAvatarModal = useChangeAvatarModal(worker);
  const [showUpdateModal, hideUpdateModal] = useModal(
    () => <UpdateProfileModal hideModal={hideUpdateModal} worker={worker} />,
    [worker],
  );

  const isCertified = containsGravyCertification(worker.activeCertificates);

  const cardActions: GenericActionOrList[] = [
    {
      a11yLabel: "Edit this GravyWorker's profile",
      label: 'Edit',
      onAction: showUpdateModal,
      id: 'btn-edit-gravyworker',
    },
  ];

  const openUrlInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const onClickCheckEmployeeId = () => {
    let url: string =
      worker.taxType === TaxTypeEnum.TAX_W2
        ? EXTERNAL_URL.w2
        : EXTERNAL_URL[1099];
    url = url
      .replace('{0}', Config.CHECKHQ_COMPANY)
      .replace('{1}', worker.checkEmployeeId);
    openUrlInNewTab(url);
  };

  return (
    <Card
      actions={!currentAdminIsCustomerAdmin ? cardActions : undefined}
      title="Profile"
    >
      <Card.Section>
        <Stack vertical gap={16}>
          <Stack align="start">
            <div style={{ cursor: 'pointer' }} onClick={showAvatarModal}>
              {
                <div style={{ position: 'relative' }}>
                  <Avatar
                    border={isCertified}
                    firstName={worker.user.firstName}
                    rounded={true}
                    size="lg"
                    src={worker.avatarUrl}
                  />
                  {isCertified && (
                    <GravyTrained
                      sx={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        width: '22px',
                        height: '22px',
                      }}
                    />
                  )}
                </div>
              }
            </div>
            <TextStack>
              <Body size="lg" weight="bold">
                {worker.user.firstName} {worker.user.middleName}{' '}
                {worker.user.lastName}
              </Body>
              <Body color="lighter">{workerGender[worker.gender]}</Body>
            </TextStack>
          </Stack>

          <Stack vertical>
            <Body weight="bold">CONTACT INFORMATION</Body>
            <Stack>
              <Body color="iconColor" size="lg">
                <Icon fixedWidth icon={faEnvelope} />
              </Body>
              <Body color="lighter">{worker.user.email}</Body>
            </Stack>

            <Stack>
              <Body color="iconColor" size="lg">
                <Icon fixedWidth icon={faMobileAlt} />
              </Body>
              <Body color="lighter">{worker.user.phoneNumber}</Body>
            </Stack>
          </Stack>

          <Stack vertical>
            <Body weight="bold">ADDITIONAL INFORMATION</Body>
            <Stack>
              <Body color="iconColor" size="lg">
                <Icon fixedWidth icon={faMobileAlt} />
              </Body>
              <Body>
                {`${
                  smartphoneType[worker.user.smartphoneType || 'NONE']
                } Phone`}
              </Body>
            </Stack>
            {worker.ownsCar && (
              <Stack>
                <Body color="iconColor" size="lg">
                  <Icon fixedWidth icon={faCheckCircle} />
                </Body>
                <Body>Access to Reliable Transportation</Body>
              </Stack>
            )}
            {getConditionToShowElegibleForW2EmploymentLabel(worker) && (
              <Stack>
                <Body color="iconColor" size="lg">
                  <Icon fixedWidth icon={faBriefcase} />
                </Body>
                <Body>Eligible for W2 employment</Body>
              </Stack>
            )}
            {worker.checkEmployeeId && (
              <Stack>
                <Body>
                  <CheckIdIcon style={{ fontSize: '20px', top: '2px' }} />
                </Body>
                <Body>
                  Check ID
                  <p
                    style={{ color: '#45A735', cursor: 'pointer' }}
                    onClick={onClickCheckEmployeeId}
                  >
                    {worker.checkEmployeeId}
                  </p>
                </Body>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Card.Section>
      <I9Form worker={worker} />
      <EmployeeDocuments worker={worker} />
      <OnboardingStatus worker={worker} />
      <BackgroundCheck
        currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
        worker={worker}
      />
      <SkillsSection
        currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
        worker={worker}
      />
      <CertificateSection
        currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
        worker={worker}
      />
      <AddressSection
        currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
        worker={worker}
      />
      <TaxSection
        currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
        worker={worker}
      />
      <LanguageSection worker={worker} />
    </Card>
  );
};

export default ProfileCard;
