import { useReactiveVar } from '@apollo/client';
import { FieldCallback } from '@area2k/use-form';
import { useEffect, useState } from 'react';

import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { DEBOUNCE_TIME } from '@/constants/general';
import AutocompleteField from '@/form/AutocompleteField';
import { useAutocompleteCustomersLazyQuery } from '@/graphql';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import {
  AccountRateTypeEnum,
  AutocompleteCustomersQuery,
} from '@/types/graphql';
import { currentAgencyVar } from '@/util/apollo/cache';

export type CustomerItem =
  AutocompleteCustomersQuery['agency']['customers']['items'][0];

export type Props = {
  fieldId: string;
  callback?: FieldCallback<CustomerItem | null>;
  filterLTA?: boolean;
};

const CustomerAutocompleteField = ({
  fieldId,
  callback,
  filterLTA = false,
}: Props) => {
  const [query, setQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const debouncedQuery = useDebouncedValue(query, DEBOUNCE_TIME);

  const currentAgency = useReactiveVar(currentAgencyVar);
  const [fetch, { data }] = useAutocompleteCustomersLazyQuery();

  useEffect(() => {
    const rateType = [AccountRateTypeEnum.MARKUP, AccountRateTypeEnum.STATIC];

    fetch({
      variables: {
        agencyId: currentAgency!.id,
        query: debouncedQuery,
        rateType,
      },
    });
  }, [debouncedQuery]);

  useEffect(() => {
    if (data && data.agency.customers.items.length) {
      setFilteredData(
        data.agency.customers.items.filter(
          (x) => x.ltaAllowed === filterLTA || !filterLTA,
        ),
      );
    } else {
      setFilteredData([]);
    }
  }, [data, filterLTA]);

  return (
    <AutocompleteField<CustomerItem>
      required
      callback={callback}
      fieldId={fieldId}
      fixedSelectHeight="lg"
      itemToKey={(item) => item.id}
      itemToString={(item) => (item ? item.name : '')}
      items={filteredData}
      label="Client*"
      placeholder="Search for clients..."
      onInputValueChange={({ inputValue }) => setQuery(inputValue || '')}
    />
  );
};

export default CustomerAutocompleteField;
