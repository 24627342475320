import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';

import CustomerRowItem, { CustomerItem } from '../Customers/CustomerRowItem';

import EditApplicantModal from './EditApplicantModal';
import EditCandidateModal from './EditCandidateModal';

import { GravyTrained } from '@/assets/icons';
import Avatar from '@/components/Avatar';
import Badge from '@/components/Badge';
import IconicButton from '@/components/IconicButton';
import RatingField from '@/components/RatingField';
import Stack from '@/components/Stack';
import TagList from '@/components/TagList';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { NOT_PROVIDED } from '@/constants/text';
import Link from '@/elements/Link';
import { TableRow, TableCell } from '@/elements/Table';
import {
  ListCandidatesQuery,
  ListWorkersQuery,
  WorkerReference,
  Maybe,
  Scalars,
} from '@/types/graphql';
import { sortBy } from '@/util/array';
import { formatDateTime } from '@/util/datetime';
import { pauseEvent } from '@/util/events';
import { parseEnums, capitalizeFirstLetter } from '@/util/text';
import {
  containsGravyCertification,
  parseInterViewQuestionarieStatus,
} from '@/util/worker';

export type CandidateItem =
  ListCandidatesQuery['agency']['candidates']['items'][0];
type WorkerItem = ListWorkersQuery['agency']['workers']['items'][0];

export const DEFAULT_CLIENT_TABS = ['Client Info', 'Creation Date', 'State'];

export const TOGGLE_ADDED_CLIENT_TABS = ['Status', 'Actions'];

const TABS_VALUE = ['CANDIDATES', 'APPLIED', 'IN_PROGRESS', 'COMPLETED'];

const headerFields = {
  CANDIDATES: [
    'City',
    'State',
    'First Name',
    'Middle Name',
    'Last Name',
    'Email',
    'Status',
    'Created on',
    'Updated on',
    'Actions',
  ],
  APPLIED: [
    'City',
    'State',
    'First Name',
    'Middle Name',
    'Last Name',
    'Email',
    'Status',
    'Created on',
    'Updated on',
    'Actions',
  ],
  IN_PROGRESS: [
    'Name',
    'Contact',
    'Address',
    'Background Status',
    'Onboarding Status',
    'Video Questionnaire Status',
  ],
  COMPLETED: ['Name', 'Contact', 'Address', 'Skills', 'Credentials'],
  CLIENTS: ['Client Info', 'Creation Date', 'State', 'Status', 'Actions'],
};

export const getHeaderFields = (tab: number) => headerFields[TABS_VALUE[tab]];

export const RenderBody = ({
  items,
  tab,
  refreshQuery,
  ratingEnabled = false,
}: {
  items: any;
  tab: number;
  refreshQuery?: () => void;
  ratingEnabled?: boolean;
}) => {
  const [selectedUser, setSelectedUser] = useState<CandidateItem>();
  const experiencesUser: WorkerReference[] = selectedUser?.references;

  const [showEditModal, hideEditModal] = useModal(
    () => (
      <EditCandidateModal
        candidate={selectedUser}
        experiences={experiencesUser}
        hideModal={hideEditModal}
      />
    ),
    [selectedUser],
  );

  const [showEditApplicantModal, hideApplicantModal] = useModal(
    () => (
      <EditApplicantModal
        applicant={selectedUser}
        experiences={experiencesUser}
        hideModal={hideApplicantModal}
      />
    ),
    [selectedUser],
  );

  const handleCandidateEdit = (item) => {
    setSelectedUser(item);
    if (tab === 0) {
      showEditModal();
    } else if (tab === 1) {
      showEditApplicantModal();
    }
  };

  const handleActiveBodyColor = (isActive: boolean | null) =>
    isActive ? 'default' : 'lightest';

  const workerDisabledInfo = (
    deactivatedBy: Maybe<Scalars['String']>,
    deactivatedAt: Maybe<Scalars['ISO8601DateTime']>,
  ) => {
    if (deactivatedBy && deactivatedAt) {
      const deactivatedByString = deactivatedBy
        .split('_')
        .map((word) => word.toLowerCase())
        .map((word) => {
          if (word.includes('fnc')) {
            return word.toUpperCase();
          }

          if (word.includes('admin')) {
            return capitalizeFirstLetter(word);
          }

          if (word.includes('worker')) {
            return capitalizeFirstLetter(word);
          }

          return word;
        })
        .join(' ');

      return (
        <Stack vertical align={'center'} gap={3}>
          <Small color={handleActiveBodyColor(false)}>
            {deactivatedByString}
          </Small>
          <Small color={handleActiveBodyColor(false)}>
            {formatDateTime(deactivatedAt)}
          </Small>
        </Stack>
      );
    }

    return null;
  };

  switch (tab) {
    case 0:
    case 1:
      return (
        <tbody>
          {items.map((item: CandidateItem) => {
            const workerIsDisabled = !item.active ?? false;

            return (
              <TableRow key={item.id} disabled={workerIsDisabled}>
                <TableCell>
                  <Body color={handleActiveBodyColor(item.active)}>
                    {item.city ? `${item.city}` : NOT_PROVIDED}
                  </Body>
                </TableCell>
                <TableCell>
                  <Body color={handleActiveBodyColor(item.active)}>
                    {item.state ? `${item.state}` : NOT_PROVIDED}
                  </Body>
                </TableCell>
                <TableCell>
                  <Body color={handleActiveBodyColor(item.active)}>
                    {item.user.firstName}
                  </Body>
                </TableCell>
                <TableCell>
                  <Body color={handleActiveBodyColor(item.active)}>
                    {item.user.middleName && item.user.middleName.length > 0
                      ? item.user.middleName
                      : NOT_PROVIDED}
                  </Body>
                </TableCell>
                <TableCell>
                  <Body color={handleActiveBodyColor(item.active)}>
                    {item.user.lastName}
                  </Body>
                </TableCell>
                <TableCell>
                  <Body color={handleActiveBodyColor(item.active)}>
                    {item.user.email}
                  </Body>
                </TableCell>
                <TableCell>
                  <Badge
                    label={item.candidateStatus}
                    status={item.active ? 'neutral' : 'disabled'}
                  />
                </TableCell>
                <TableCell>
                  <Body color={handleActiveBodyColor(item.active)}>
                    {formatDateTime(item.createdAt)}
                  </Body>
                </TableCell>
                <TableCell>
                  <Body color={handleActiveBodyColor(item.active)}>
                    {formatDateTime(item.updatedAt)}
                  </Body>
                </TableCell>
                <TableCell>
                  <Stack>
                    <IconicButton
                      a11yLabel="edit"
                      appearance="clear"
                      icon={faPen}
                      id={`edit-${item.id}`}
                      size="sm"
                      onClick={pauseEvent(() => handleCandidateEdit(item))}
                    />
                  </Stack>
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      );

    case 2:
      return (
        <tbody>
          {items.map((item: CandidateItem) => {
            const workerIsDisabled = !item.active ?? false;
            return (
              <TableRow key={item.id} disabled={workerIsDisabled}>
                <TableCell>
                  <Stack gap={20}>
                    <TextStack spacing="tight">
                      <Link id={`link-${item.id}`} to={`${item.id}`}>
                        <Stack vertical gap={10}>
                          <Body
                            color={item.active ? 'inherit' : 'lightest'}
                            weight="medium"
                          >
                            {item.user.firstName} {item.user.middleName}{' '}
                            {item.user.lastName}
                          </Body>
                          {workerIsDisabled &&
                            workerDisabledInfo(
                              item.deactivatedBy,
                              item.deactivatedAt,
                            )}
                        </Stack>
                      </Link>
                    </TextStack>
                  </Stack>
                </TableCell>
                <TableCell>
                  <TextStack spacing="tight">
                    <Body color={handleActiveBodyColor(item.active)}>
                      {item.user.phoneNumber}
                    </Body>
                    <Small color={item.active ? 'lighter' : 'lightest'}>
                      {item.user.email}
                    </Small>
                  </TextStack>
                </TableCell>
                <TableCell>
                  <Body color={handleActiveBodyColor(item.active)}>
                    {`${item.city}, ${item.state}`}
                  </Body>
                </TableCell>
                <TableCell>
                  <Badge
                    label={
                      item.backgroundStatus === 'NA'
                        ? 'N/A'
                        : parseEnums(item.backgroundStatus ?? 'Pending')
                    }
                    status={item.active ? 'neutral' : 'disabled'}
                  />
                </TableCell>
                <TableCell>
                  <Badge
                    label={parseEnums(item.onboardingStatus ?? 'Pending')}
                    status={item.active ? 'neutral' : 'disabled'}
                  />
                </TableCell>
                <TableCell>
                  <Badge
                    label={parseInterViewQuestionarieStatus(
                      item.interviewStatus,
                    )}
                    status={
                      parseInterViewQuestionarieStatus(item.interviewStatus) ===
                      'To Review'
                        ? 'danger'
                        : item.active
                          ? 'neutral'
                          : 'disabled'
                    }
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      );
    case 3:
      return (
        <tbody>
          {items.map((item: WorkerItem) => {
            const workerIsDisabled = !item.active ?? false;
            const isCertified = containsGravyCertification(
              item.activeCertificates,
            );
            return (
              <TableRow key={item.id} disabled={workerIsDisabled}>
                <TableCell>
                  <Stack gap={20} style={{ marginBottom: '10px' }}>
                    {
                      <div style={{ position: 'relative' }}>
                        <Avatar
                          border={isCertified}
                          firstName={item.user.firstName}
                          rounded={true}
                          size="m"
                          src={item.avatarUrl}
                        />
                        {isCertified && (
                          <GravyTrained
                            sx={{
                              position: 'absolute',
                              right: 0,
                              bottom: 0,
                              width: '18px',
                              height: '18px',
                            }}
                          />
                        )}
                      </div>
                    }
                    <Stack vertical gap={10}>
                      <TextStack spacing="extra-loose">
                        <Link id={`link-${item.id}`} to={`${item.id}`}>
                          <Body
                            color={item.active ? 'inherit' : 'lightest'}
                            size="lg"
                            weight="medium"
                          >
                            {item.user.firstName} {item.user.middleName}{' '}
                            {item.user.lastName}
                          </Body>
                        </Link>
                      </TextStack>
                    </Stack>
                  </Stack>
                  {ratingEnabled && item.jobsRatingCount > 0 && (
                    <RatingField
                      initialRating={item.averageRating}
                      readOnly={true}
                      showNumberLabel={true}
                    />
                  )}
                  {workerIsDisabled &&
                    workerDisabledInfo(item.deactivatedBy, item.deactivatedAt)}
                </TableCell>
                <TableCell>
                  <TextStack spacing="tight">
                    <Body color={handleActiveBodyColor(item.active)}>
                      {item.user.phoneNumber}
                    </Body>
                    <Small color={item.active ? 'lighter' : 'lightest'}>
                      {item.user.email}
                    </Small>
                  </TextStack>
                </TableCell>
                <TableCell>
                  <TextStack spacing="tight">
                    <Body color={handleActiveBodyColor(item.active)}>
                      {item.addressLine1}
                    </Body>
                    <Small
                      color={item.active ? 'lighter' : 'lightest'}
                    >{`${item.city}, ${item.state}`}</Small>
                  </TextStack>
                </TableCell>
                <TableCell width="sm">
                  <TagList
                    tags={sortBy(item.skills, 'name').map(
                      (skill) => skill.name,
                    )}
                  />
                </TableCell>
                <TableCell width={'xs'}>
                  <TagList
                    showIcon={true}
                    style={{ backgroundColor: '#e0eaff' }}
                    tags={sortBy(item.activeCertificates, 'name').map(
                      (cert) => cert.name,
                    )}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      );
    case 4:
      return (
        <tbody>
          {items.map((item: CustomerItem, index: number) => {
            return (
              <CustomerRowItem
                key={item.id}
                index={index}
                item={item}
                refreshQuery={() => {
                  refreshQuery?.();
                }}
              />
            );
          })}
        </tbody>
      );
    default:
      return <></>;
  }
};
