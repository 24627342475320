import { SubmitHelpers } from '@area2k/use-form';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Divider } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getRoundOff } from '../util';

import { LinkedIcon, UnlinkIcon } from '@/assets/icons';
import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import MaskedInput from '@/components/MaskedInput';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { Body, Subheading } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { GAEvent } from '@/constants/gaevents';
import { MAX_PAY } from '@/constants/rates';
import Form from '@/form';
import {
  useGetRateRangeLazyQuery,
  useUpdatePaymentJobMutation,
} from '@/graphql';
import useAuth from '@/hooks/useAuth';
import styled from '@/styles';
import { GetJobQuery, Scalars } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import { handleMutationFormError } from '@/util/error';
import {
  centsToCurrency,
  currencyToCents,
  getPreciseNumber,
} from '@/util/number';
import { CalculateCost } from '@/util/payments';

export type Props = {
  job: GetJobQuery['job'];
  hideModal: () => Scalars['Void'];
  isHoliday: boolean;
};

type FormValues = {
  payRate: Scalars['String'];
  costRate: Scalars['String'];
};

const DEFAULT_RATE = 1.5;

const RowBorderWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const RatingWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '10px',
});

const PayRateInputWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const UpdateMarkupPaymentModal = ({ job, hideModal, isHoliday }: Props) => {
  const [minPay, setMinPay] = useState(0);
  const [maxPay, setMaxPay] = useState(MAX_PAY);

  const { logEvent } = useAnalytics();
  const initPayRate = isHoliday
    ? job.originalPayRate || job.payRate / DEFAULT_RATE
    : job.payRate;
  const initCostRate = isHoliday
    ? job.originalCostRate || job.costRate / DEFAULT_RATE
    : job.costRate;

  const [holidayRates, setHolidayRates] = useState({
    payRate: job.payRate,
    costRate: job.costRate,
  });
  const [markup, setMarkup] = useState(job.markupPercent!);
  const { currentAdminIsCustomerAdmin } = useAuth();
  const [isRateLocked, setIsRateLocked] = useState(
    job.isRateLock && !currentAdminIsCustomerAdmin,
  );
  const lockingFeature = !currentAdminIsCustomerAdmin;

  const [fetch, { data }] = useGetRateRangeLazyQuery();
  useEffect(() => {
    fetch({
      variables: { addressId: job.address?.id, skillId: job.skill?.id },
    });
  }, [job]);
  useEffect(() => {
    if (data && data.rateRange && data.rateRange.length) {
      setMinPay(data.rateRange[0].minimum);
      setMaxPay(data.rateRange[0].maximum);
    }
  }, [data]);

  const [formValues, setFormValues] = useState<FormValues>({
    payRate: centsToCurrency(initPayRate),
    costRate: centsToCurrency(initCostRate),
  });

  useEffect(() => {
    if (isHoliday) {
      setHolidayRates({
        payRate: Math.round(
          getPreciseNumber(currencyToCents(formValues.payRate || '0')) *
            DEFAULT_RATE,
        ),
        costRate: Math.round(
          getPreciseNumber(currencyToCents(formValues.costRate || '0')) *
            DEFAULT_RATE,
        ),
      });
    }
  }, [formValues, isHoliday]);

  const [updatePayments, { loading }] = useUpdatePaymentJobMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(job),
        fields: {
          job() {},
        },
      });
    },
  });

  const handleInputChange = (
    key: Scalars['String'],
    value: Scalars['String'],
  ) => {
    const payValue = value?.replace('$', '');

    if (lockingFeature && isRateLocked) {
      setFormValues({
        ...formValues,
        [key]: payValue,
      });
      if (Number(payValue) > 0) {
        const result = Number(formValues.costRate) / Number(payValue) - 1;
        setMarkup(getRoundOff(result * 100));
      }
    } else {
      const costRate = centsToCurrency(CalculateCost(payValue || '0', markup));
      setFormValues({
        ...formValues,
        [key]: payValue,
        costRate,
      });
    }
  };
  const toggleLocking = () => {
    if (isRateLocked) {
      setMarkup(job.markupPercent!);
      const costRate = centsToCurrency(
        CalculateCost(formValues.payRate || '0', job.markupPercent!),
      );
      setFormValues((val) => ({ ...val, costRate }));
    } else {
      setFormValues((val) => ({
        ...val,
        costRate: centsToCurrency(initCostRate),
      }));
    }
    setIsRateLocked(!isRateLocked);
  };

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        const payRate = getPreciseNumber(currencyToCents(formValues.payRate));
        const costRate = getPreciseNumber(currencyToCents(formValues.costRate));
        await updatePayments({
          variables: {
            jobId: job.id,
            originalPayRate: payRate,
            originalCostRate: costRate,
            payRate: isHoliday ? holidayRates.payRate : payRate,
            costRate: isHoliday ? holidayRates.costRate : costRate,
            markupPercent: !currentAdminIsCustomerAdmin ? markup : null,
            isRateLock: isRateLocked,
          },
        });
        logEvent(GAEvent.EditJobRates, job.id);
        hideModal();
      } catch (error) {
        handleMutationFormError(error, {
          setFormError,
          errorMap: {
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        });
      }
    },
    [formValues, holidayRates, markup],
  );

  const inValidPayRate =
    currencyToCents(formValues.payRate || '0') < minPay ||
    Number(formValues.payRate) >= Number(formValues.costRate);
  const isDisabled =
    inValidPayRate || formValues.payRate === '' || formValues.costRate === '';

  const color = useMemo(() => {
    const payValue = parseFloat(formValues.payRate) * 100;
    if (payValue < minPay!) {
      return '#DC1515';
    } else if (payValue < maxPay!) {
      return '#EC7F00';
    } else {
      return '#45A735';
    }
  }, [formValues, minPay, maxPay]);

  return (
    <Modal
      disableClickout
      size="xs"
      title={'Change Payment'}
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form initialValues={formValues} onSubmit={handleSubmit}>
          <Subheading css={{ fontWeight: 'bold', marginBottom: '5px' }}>
            Set Pay Rate
          </Subheading>

          <Stack
            css={{
              border: '2px solid #D3D3D3',
              borderRadius: '8px',
              padding: '12px 14px',
            }}
            horizontalGap={9}
          >
            <div style={{ width: lockingFeature ? '92%' : '100%' }}>
              <RowBorderWrapper>
                <Body size={'lg'} weight={'medium'}>
                  Pay Rate
                </Body>
                <RatingWrapper>
                  <PayRateInputWrapper>
                    <MaskedInput
                      blocks={{
                        num: {
                          mask: Number,
                          thousandsSeparator: ',',
                          radix: '.',
                          max: 999,
                          scale: 2,
                          padFractionalZeros: true,
                          signed: false,
                          normalizeZeros: true,
                        },
                      }}
                      mask={'$num'}
                      style={{
                        width: '100px',
                        textAlign: 'end',
                        fontSize: '20px',
                        fontWeight: '700',
                        color: `${color}`,
                      }}
                      value={formValues.payRate ? `$${formValues.payRate}` : ''}
                      onAccept={(value) => handleInputChange('payRate', value)}
                    />
                  </PayRateInputWrapper>
                </RatingWrapper>
              </RowBorderWrapper>
              {lockingFeature && (
                <>
                  <Divider sx={{ my: '12px' }} />
                  <RowBorderWrapper>
                    <Body size={'lg'} weight={'medium'}>
                      Bill Rate
                    </Body>
                    <RatingWrapper>
                      <PayRateInputWrapper>
                        <MaskedInput
                          blocks={{
                            num: {
                              mask: Number,
                              thousandsSeparator: ',',
                              radix: '.',
                              max: 999,
                              scale: 2,
                              padFractionalZeros: true,
                              signed: false,
                              normalizeZeros: true,
                            },
                          }}
                          disabled={true}
                          id="bill-rate-input"
                          mask={'$num'}
                          style={{
                            width: '100px',
                            textAlign: 'end',
                            fontSize: '20px',
                            fontWeight: '700',
                          }}
                          value={
                            formValues.costRate ? `$${formValues.costRate}` : ''
                          }
                        />
                      </PayRateInputWrapper>
                    </RatingWrapper>
                  </RowBorderWrapper>
                </>
              )}
            </div>
            {lockingFeature && (
              <div style={{ cursor: 'pointer' }} onClick={toggleLocking}>
                {isRateLocked ? (
                  <UnlinkIcon
                    id="unlink-icon"
                    sx={{ width: '44px', height: '49px' }}
                  />
                ) : (
                  <LinkedIcon
                    id="link-icon"
                    sx={{ width: '44px', height: '49px' }}
                  />
                )}
              </div>
            )}
          </Stack>
          {isRateLocked && (markup !== job.markupPercent || isDisabled) && (
            <Alert
              css={{
                marginTop: '8px',
              }}
              description={`Payment adjustments without bill rate adjustments need approval from a Sales Manager`}
              icon={faExclamationTriangle}
              status={'warning2'}
              title="Payment Adjustments"
            />
          )}

          <Stack justify="center">
            <Button
              a11yLabel="Submit form"
              disabled={isDisabled}
              isLoading={loading}
              label="Save"
              style={{ width: '100%', marginTop: '8px' }}
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default UpdateMarkupPaymentModal;
