import { faInfoCircle, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import {
  CancelOutlined,
  CheckCircleOutline,
  ChatOutlined,
  DoDisturbOutlined,
} from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import { Box, MenuItem, Stack as MuiStack } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useNavigate } from 'react-router-dom';

import { TimeSheetStatus, calculateNetTime } from '../util';

import ProfileModal from './ProfileModal';
import ProfileModalRevamp from './ProfileModal/ProfileModalRevamp';

import {
  CancelNoShowIcon,
  EditTimesheetIcon,
  GravyTrained,
  NoshowIcon,
  UnApproveIcon,
} from '@/assets/icons';
import ClientApproveIcon from '@/assets/icons/ClientApprove';
import ClientUnRejectIcon from '@/assets/icons/ClientUnReject';
import Avatar from '@/components/Avatar';
import StyledChip from '@/components/Chip';
import RatingField from '@/components/RatingField';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import Icon from '@/elements/Icon';
import Text from '@/elements/Text';
import { containsGravyCertification } from '@/util/worker';

const IconColor = '#7A7A7A';
export const getShiftTime = (timesheet) => {
  const {
    netApprovedTime,
    reportedCheckinAt,
    reportedCheckoutAt,
    reportedBreakMinutes,
    checkinAt,
    checkoutAt,
  } = timesheet;
  let approvedTime = netApprovedTime;
  if (!approvedTime && reportedCheckinAt && reportedCheckoutAt) {
    approvedTime = calculateNetTime(
      reportedCheckinAt,
      reportedCheckoutAt,
      reportedBreakMinutes,
    );
  } else if (!approvedTime && checkinAt && checkoutAt) {
    approvedTime = calculateNetTime(
      checkinAt,
      checkoutAt,
      reportedBreakMinutes,
    );
  }
  return approvedTime / 60;
};

export const RenderWorkerInfo = ({
  workerInfo,
  isClientAdmin,
  StatusChip,
  disable,
  handleAddComment,
  timeSheet,
}: {
  workerInfo: any;
  isClientAdmin: boolean;
  StatusChip?: ReactNode;
  disable?: boolean;
  handleAddComment?: any;
  timeSheet?: any;
}) => {
  const navigate = useNavigate();
  const [showModal, hideModal] = useModal(
    () => <ProfileModal ProfileInfo={workerInfo} hideModal={hideModal} />,
    [workerInfo],
  );
  const [showProfileRevampModal, hideProfileRevampModal] = useModal(
    () => (
      <ProfileModalRevamp
        ProfileInfo={workerInfo}
        hideModal={hideProfileRevampModal}
        isClientAdmin={isClientAdmin}
      />
    ),
    [workerInfo],
  );
  const showRating = useFeatureValue(FEATURE_TOGGLE.WorkerRating, false);

  const [highlight, setHighlight] = useState(false);
  const isCertified = containsGravyCertification(workerInfo.activeCertificates);

  const handleClick = () => {
    if (isClientAdmin && showRating) {
      showProfileRevampModal();
    }
    if (!isClientAdmin) {
      showProfileRevampModal();
    }
  };
  const handleMouseEnter = () => setHighlight(true);
  const handleMouseLeave = () => setHighlight(false);

  return (
    <MuiStack
      direction={'row'}
      spacing={1.5}
      sx={
        disable
          ? { opacity: 0.55, ':hover': { cursor: 'pointer' } }
          : { ':hover': { cursor: 'pointer' } }
      }
    >
      <MuiStack
        alignItems={'center'}
        id="worker-info-div"
        justifyContent={'center'}
        spacing={1.7}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box sx={{ position: 'relative' }}>
          <Avatar
            border={isCertified}
            rounded={true}
            size="lg2"
            src={workerInfo.avatarUrl}
          />
          {isCertified && (
            <GravyTrained
              id="gravy-badge"
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '28px',
                height: '28px',
              }}
            />
          )}
        </Box>
        <div className="worker-status-chip">{StatusChip}</div>
      </MuiStack>
      <MuiStack spacing={1.2}>
        <div
          style={{ minHeight: '78px' }}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <WorkerDesc
            highlight={highlight}
            isClientAdmin={isClientAdmin}
            showRating={true}
            workerInfo={workerInfo}
          />
        </div>
        {showRating && (
          <RatingSection
            handleAddComment={handleAddComment}
            isClientAdmin={isClientAdmin}
            timeSheet={timeSheet}
          />
        )}
      </MuiStack>
    </MuiStack>
  );
};

export const RatingSection = ({
  timeSheet,
  handleAddComment,
  isClientAdmin,
  phoneOnly,
}: {
  timeSheet: any;
  handleAddComment?: () => void;
  phoneOnly?: boolean;
  isClientAdmin: boolean;
}) => {
  const hasComments = () => {
    if (
      timeSheet &&
      timeSheet.timesheetComments &&
      timeSheet.timesheetComments.length
    ) {
      if (isClientAdmin) {
        return (
          timeSheet.timesheetComments.filter(
            (c) => c.actorType === 'CustomerAdmin',
          ).length > 0
        );
      }
      return true;
    }
    return false;
  };

  return (
    <>
      {phoneOnly && (
        <span style={{ marginTop: '4px', marginLeft: '10px' }}>Rate:</span>
      )}
      <div
        id="rating-section"
        style={{ cursor: 'pointer' }}
        onClick={() => timeSheet && handleAddComment?.()}
      >
        <MuiStack
          className={timeSheet ? '' : 'rating-stack rating-disabled'}
          direction={'row'}
          spacing={2}
          sx={{
            border: '1px solid #000',
            borderRadius: '20px',
            padding: '5px',
            borderColor: '#999999',
            paddingLeft: '11px',
            paddingRight: '11px',
          }}
        >
          <RatingField
            initialRating={timeSheet?.rating || 0}
            readOnly={true}
            showNumberLabel={false}
          />
          {hasComments() ? (
            <Icon
              fixedWidth
              color="#FF5C00"
              icon={faCommentDots}
              size={'lg'}
              style={{ color: '#45a735', marginTop: '3px' }}
            />
          ) : (
            <div className="icon-add-rating">
              <svg
                fill="none"
                height="17"
                viewBox="0 0 19 17"
                width="19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.99996 0.5C13.5833 0.5 17.3333 3.48333 17.3333 7.16667C17.3333 7.65 17.2666 8.11667 17.15 8.56667C16.6583 8.31667 16.125 8.13333 15.5666 8.05C15.6333 7.76667 15.6666 7.46667 15.6666 7.16667C15.6666 4.40833 12.6833 2.16667 8.99996 2.16667C5.31663 2.16667 2.33329 4.40833 2.33329 7.16667C2.33329 9.925 5.31663 12.1667 8.99996 12.1667L9.90829 12.125L9.83329 13L9.89996 13.7917L8.99996 13.8333C8.00829 13.8333 7.01663 13.6917 6.05829 13.4167C4.53329 14.6667 2.64163 15.4083 0.666626 15.5C2.60829 13.5583 2.95829 12.25 2.95829 11.75C1.54996 10.6417 0.708293 8.95833 0.666626 7.16667C0.666626 3.48333 4.41663 0.5 8.99996 0.5ZM14 9.66667H15.6666V12.1667H18.1666V13.8333H15.6666V16.3333H14V13.8333H11.5V12.1667H14V9.66667Z"
                  fill="#A1A1A1"
                />
              </svg>
            </div>
          )}
        </MuiStack>
      </div>
    </>
  );
};

export const WorkerDesc = ({
  workerInfo,
  isClientAdmin,
  showRating,
  highlight = false,
}: {
  workerInfo: any;
  isClientAdmin: boolean;
  showRating?: boolean;
  highlight?: boolean;
}) => {
  const { firstName, middleName, lastName, email, phoneNumber } =
    workerInfo.user;
  return (
    <TextStack>
      <Body
        color={highlight ? 'themeDefault' : undefined}
        css={{
          lineHeight: '1 !important',
          textDecoration: highlight ? 'underline' : 'none',
        }}
        weight="medium"
      >
        {firstName} {middleName} {lastName}
      </Body>
      {!isClientAdmin && (
        <>
          <Small>{email}</Small>
          <Small>{phoneNumber}</Small>
        </>
      )}
      {showRating && (
        <MuiStack alignItems={'center'} direction={'row'} spacing={0.6}>
          <StarIcon
            id="star-icon"
            sx={{ color: '#faaf00', width: '20px', height: '20px' }}
          />
          <Text size={'md'} weight={'semibold'}>
            {workerInfo.averageRating
              ? workerInfo.averageRating.toFixed(1)
              : '0.0'}
          </Text>
          <Text color={'lighter'}>{`(${workerInfo.jobsRatingCount})`}</Text>
        </MuiStack>
      )}
    </TextStack>
  );
};

export const renderTimeSheetStatusChip = (status?: TimeSheetStatus) => {
  if (!status) return '-';
  switch (status) {
    case TimeSheetStatus.INCOMPLETE:
      return (
        <Text color={'light'} size={'sm'}>
          {'Incomplete'}
        </Text>
      );
    case TimeSheetStatus.PENDING:
      return <StyledChip label={'Pending'} size="small" />;
    case TimeSheetStatus.APPROVED:
      return <StyledChip color="success" label={'Approved'} size="small" />;
    case TimeSheetStatus.REJECTED:
      return <StyledChip color="error" label={'Rejected'} size="small" />;
    default:
      break;
  }
};

export const MenuItemsObject = {
  addComment: (handleAddComment, showComment) =>
    showComment ? (
      <MenuItem id="btn-add-comment" onClick={handleAddComment}>
        <ChatOutlined
          fontSize="small"
          htmlColor={IconColor}
          style={{ marginRight: '10px' }}
        />
        Add Comment
      </MenuItem>
    ) : undefined,
  approve: (handleApproveTimesheet) => (
    <MenuItem id="btn-approve" onClick={handleApproveTimesheet}>
      <CheckCircleOutline
        fontSize="small"
        htmlColor={IconColor}
        style={{ marginRight: '10px' }}
      />
      Approve
    </MenuItem>
  ),
  reject: (handleRejectTimesheet) => (
    <MenuItem id="btn-reject" onClick={handleRejectTimesheet}>
      <CancelOutlined
        fontSize="small"
        htmlColor={IconColor}
        style={{ marginRight: '10px' }}
      />
      Reject
    </MenuItem>
  ),
  unReject: (handleUnrejectTimesheet) => (
    <MenuItem id="btn-unreject" onClick={handleUnrejectTimesheet}>
      <ClientUnRejectIcon
        fontSize="small"
        htmlColor={IconColor}
        style={{ marginRight: '10px' }}
      />
      Unreject
    </MenuItem>
  ),
  unApprove: (handleUnapproveTimesheets) => (
    <MenuItem id="btn-unapprove" onClick={handleUnapproveTimesheets}>
      <UnApproveIcon
        style={{
          marginLeft: 1,
          fontSize: '18px',
          marginRight: 10,
        }}
      />
      Unapprove
    </MenuItem>
  ),
  enable: (enableActionCallback) => (
    <MenuItem id="btn-enable" onClick={enableActionCallback}>
      <ClientApproveIcon
        style={{
          marginLeft: 4,
          fontSize: '18px',
          marginRight: 10,
        }}
      />
      Enable
    </MenuItem>
  ),
  disable: (disableActionCallback) => (
    <MenuItem id="btn-disable" onClick={disableActionCallback}>
      <NoshowIcon
        style={{
          marginLeft: 4,
          fontSize: '17px',
          marginRight: 10,
        }}
      />
      Disable
    </MenuItem>
  ),
  noShow: (handleToggleShowed) => (
    <MenuItem id="btn-noshow" onClick={handleToggleShowed}>
      <NoshowIcon
        style={{
          marginLeft: 4,
          fontSize: '17px',
          marginRight: 10,
        }}
      />
      No show
    </MenuItem>
  ),
  cancelNoshow: (handleToggleShowed) => (
    <MenuItem id="btn-cancel-noshow" onClick={handleToggleShowed}>
      <CancelNoShowIcon
        style={{
          marginLeft: 5,
          fontSize: '17px',
          marginRight: 10,
        }}
      />
      Cancel no show
    </MenuItem>
  ),
  removeWorker: (handleRemoveTimesheet) => (
    <MenuItem id="btn-remove" onClick={handleRemoveTimesheet}>
      <DoDisturbOutlined
        fontSize="small"
        htmlColor={IconColor}
        style={{ marginRight: '10px' }}
      />
      Remove
    </MenuItem>
  ),
  editTimesheet: (handleEditTs) => (
    <MenuItem key={'editts'} id="btn-timesheet" onClick={handleEditTs}>
      <EditTimesheetIcon
        htmlColor={IconColor}
        style={{
          fontSize: '18px',
          marginRight: '10px',
        }}
      />
      Timesheet
    </MenuItem>
  ),
};

export const RemovedWorkerChip = (
  <StyledChip
    icon={
      <Icon
        fixedWidth
        color="#FF5C00"
        icon={faInfoCircle}
        style={{ fontSize: '14px' }}
      />
    }
    label="Removed"
    size="small"
    sx={{
      fontSize: '0.8rem',
      bgcolor: '#FFEFE1',
      color: '#FF5C00',
    }}
  />
);

export const NoShowChip = (
  <StyledChip color="error" label="No Show" size="small" />
);
export const HiredChip = (
  <StyledChip color="success" label="Hired" size="small" />
);

export const ConfirmedChip = (
  <StyledChip
    label="Confirmed"
    size="small"
    sx={{
      fontSize: '0.75rem',
      bgcolor: '#45a735',
      color: '#FFF',
    }}
  />
);
