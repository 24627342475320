import { useReactiveVar } from '@apollo/client';
import useModal from '@area2k/use-modal';
import {
  faDollarSign,
  faPercentage,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import CreateAccountModal from './CreateAccountModal';
import {
  BillingAccountListItemStyles,
  BillingAccountStatusPillStyles,
  CreateAccountDropDownIconsStyles,
  StyledStackItemCreateAccountModal,
} from './styles';

import ActiveCard from '@/components/ActiveCard';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Dropdown from '@/components/Dropdown';
import EmptyState from '@/components/EmptyState';
import ResourceList from '@/components/ResourceList';
import Stack from '@/components/Stack';
import { Body, Small, Subheading } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import Icon from '@/elements/Icon';
import { Role } from '@/routes/PrivateRoute';
import { AccountRateTypeEnum, GetCustomerQuery } from '@/types/graphql';
import { currentAdminVar } from '@/util/apollo/cache';

type Item = GetCustomerQuery['customer']['accounts'][0];

type Props = {
  customer: GetCustomerQuery['customer'];
  accordion?: boolean;
};

enum BillingAccountStatus {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
}

const AccountList = ({ customer, accordion = false }: Props) => {
  const [createModalType, setCreateModalType] = useState<string>('');
  const [state, setState] = useState<{
    accounts: Item[];
    billingAccountStatus: BillingAccountStatus;
  }>({
    accounts: customer.accounts.filter((account) => account.active),
    billingAccountStatus: BillingAccountStatus.ENABLED,
  });
  const [showCreateModal, hideCreateModal] = useModal(
    () => (
      <CreateAccountModal
        customer={customer}
        hideModal={hideCreateModal}
        rateType={createModalType}
      />
    ),
    [customer, createModalType],
  );

  const currentAdmin = useReactiveVar(currentAdminVar);
  const isTenantAdmin = currentAdmin?.role === Role.TENANT_ADMIN;

  const renderItem = (item: Item) => (
    <ResourceList.Item key={item.id} to={`accounts/${item.id}`}>
      <div style={BillingAccountListItemStyles}>
        <Body>{item.name}</Body>
        {!item.active && (
          <div style={BillingAccountStatusPillStyles}>
            <Small color={'default'}>Disabled</Small>
          </div>
        )}
      </div>
    </ResourceList.Item>
  );

  const handleShowCreateModal = (rateType: string) => {
    setCreateModalType(rateType);
    showCreateModal();
  };

  const handleCreateModalOnChange = () => {
    setCreateModalType(AccountRateTypeEnum.MARKUP);
    showCreateModal();
  };

  const handleBillingAccountStatusChange = (status: BillingAccountStatus) => {
    if (status !== state.billingAccountStatus) {
      setState({
        ...state,
        billingAccountStatus: status,
        accounts: customer.accounts.filter((account) => {
          return status === BillingAccountStatus.ENABLED
            ? account.active
            : !account.active;
        }),
      });
    }
    const element = document.getElementById('outer-view');
    element?.click();
  };

  const DropdownCreateAccountContent = () => (
    <Stack vertical gap={5} style={{}}>
      <Stack>
        <StyledStackItemCreateAccountModal
          onClick={() => handleShowCreateModal(AccountRateTypeEnum.STATIC)}
        >
          <Icon
            fixedWidth
            css={CreateAccountDropDownIconsStyles}
            icon={faDollarSign}
          />
          <Subheading size="md" width="100%">
            Customs Rates
          </Subheading>
        </StyledStackItemCreateAccountModal>
      </Stack>
      <Stack>
        <StyledStackItemCreateAccountModal
          onClick={() => handleShowCreateModal(AccountRateTypeEnum.MARKUP)}
        >
          <Icon
            fixedWidth
            css={CreateAccountDropDownIconsStyles}
            icon={faPercentage}
          />
          <Subheading size="md" width="100%">
            Markup
          </Subheading>
        </StyledStackItemCreateAccountModal>
      </Stack>
    </Stack>
  );

  const DropdownBillingStatusContent = () => (
    <Stack vertical gap={2}>
      <Stack>
        <StyledStackItemCreateAccountModal
          onClick={() =>
            handleBillingAccountStatusChange(BillingAccountStatus.ENABLED)
          }
        >
          <Subheading size="md" textTransform={'capitalize'} width={100}>
            {BillingAccountStatus.ENABLED}
          </Subheading>
        </StyledStackItemCreateAccountModal>
      </Stack>
      <Stack>
        <StyledStackItemCreateAccountModal
          onClick={() =>
            handleBillingAccountStatusChange(BillingAccountStatus.DISABLED)
          }
        >
          <Subheading size="md" textTransform={'capitalize'} width="80%">
            {BillingAccountStatus.DISABLED}
          </Subheading>
        </StyledStackItemCreateAccountModal>
      </Stack>
    </Stack>
  );

  return (
    <ActiveCard active={customer.admins.items.length > 0}>
      <Card
        accordion={accordion}
        actions={
          !isTenantAdmin && customer.accounts.length === 0
            ? [
                {
                  a11yLabel: 'Create a new account',
                  label: 'New',
                  onAction: handleCreateModalOnChange,
                  id: 'new-account',
                },
              ]
            : undefined
        }
        actionsDropdown={
          <>
            {
              <Dropdown
                Content={() => <DropdownBillingStatusContent />}
                justify="right"
                size="sm"
              >
                <Button
                  hasPopover
                  a11yLabel={'Billing Account Status'}
                  appearance="outline"
                  css={{
                    height: '38px',
                    textTransform: 'capitalize',
                  }}
                  id="billing-account-status-btn"
                  label={state.billingAccountStatus}
                  type="button"
                />
              </Dropdown>
            }
            {isTenantAdmin ? (
              <>
                <Dropdown
                  Content={() => <DropdownCreateAccountContent />}
                  justify="right"
                  size="sm"
                >
                  <Button
                    hasPopover
                    a11yLabel={'Create billing account'}
                    css={{
                      backgroundColor: '#45A735',
                      height: '38px',
                      color: 'white',
                      ':hover': { backgroundColor: '#45A735 !important' },
                    }}
                    iconLeft={faPlus}
                    id="create-billing-account-btn"
                    label="Create Account"
                    type="button"
                  />
                </Dropdown>
              </>
            ) : null}
          </>
        }
        id="outer-view"
        open={!(customer.admins.items.length > 0)}
        title="Billing Accounts"
      >
        <ResourceList
          emptyState={
            <EmptyState
              text={
                state.billingAccountStatus === BillingAccountStatus.DISABLED
                  ? 'No disabled accounts yet'
                  : 'Add a new billing account by clicking the button above'
              }
              title={'No accounts'}
            />
          }
          items={state.accounts}
          renderItem={renderItem}
        />
      </Card>
    </ActiveCard>
  );
};

export default AccountList;
