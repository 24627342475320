import useModal from '@area2k/use-modal';
import { Share } from '@capacitor/share';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { CloseOutlined, MoreVert } from '@mui/icons-material';
import {
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Snackbar,
  IconButton as MuiIconButton,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import PageSkeleton from './PageSkeleton';
import WorkerCompleted from './WorkerCompleted';
import WorkerInProgress from './WorkerInProgress';
import UpdatePasswordToWorkerModal, {
  WorkerItem,
} from './modals/UpdatePasswordToWorkerModal';
import useChangeAvatarModal from './useChangeAvatarModal';

import { CameraIcon, GravyTrained, LinkIcon, ResetIcon } from '@/assets/icons';
import Avatar from '@/components/Avatar';
import Badge from '@/components/Badge';
import Page from '@/components/Page';
import RatingField from '@/components/RatingField';
import Stack from '@/components/Stack';
import { Body, Small, Title } from '@/components/Typography';
import Config from '@/config';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { TIME_TO_REFRESH } from '@/constants/general';
import { useGetWorkerQuery } from '@/graphql';
import useAuth from '@/hooks/useAuth';
import ROUTES from '@/routes/routes';
import { CandidateStatusEnum, WorkerStatusEnum } from '@/types/graphql';
import { isWeb } from '@/util/platform';
import { containsGravyCertification } from '@/util/worker';

const Worker = () => {
  const { workerId } = useParams();
  const filterDate = useMemo(() => new Date().toISOString(), []);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [toastMsg, setToastMsg] = useState('');

  const handleClose = (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastMsg('');
  };

  const query = useGetWorkerQuery({
    variables: { filterDate, workerId },
    pollInterval: TIME_TO_REFRESH,
  });
  const ratingEnabled = useFeatureValue(FEATURE_TOGGLE.WorkerRating, false);

  const { currentAdminIsCustomerAdmin } = useAuth();
  const showAvatarModal = useChangeAvatarModal(query.data?.worker);
  const [showPasswordModal, hidePasswordModal] = useModal(
    (worker: WorkerItem) => (
      <UpdatePasswordToWorkerModal
        hideModal={hidePasswordModal}
        worker={worker}
      />
    ),
    [query.data],
  );

  const copyLink = async (workerId) => {
    handleCloseMenu();
    if (isWeb()) {
      try {
        navigator.clipboard.writeText(
          window.location.hostname + '/workers/' + workerId,
        );
        setToastMsg('Link Copied');
      } catch {
        setToastMsg('Unable to copy link');
      }
    } else {
      try {
        await Share.share({
          url: Config.BASE_URL + '/workers/' + workerId,
        });
      } catch {
        setToastMsg('Unable to share link');
      }
    }
  };

  if (!query.data) {
    return <PageSkeleton />;
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const worker = query.data.worker;
  const isWorkerInProgress =
    worker.candidateStatus === CandidateStatusEnum.START_HIRE &&
    worker.status !== WorkerStatusEnum.HIRED;

  if (currentAdminIsCustomerAdmin && isWorkerInProgress) {
    return <Navigate to={ROUTES.basePath} />;
  }
  const isCertified = containsGravyCertification(worker.activeCertificates);
  return (
    <Page
      headerMenu={
        !currentAdminIsCustomerAdmin && (
          <Stack style={{ justifyContent: 'flex-end', flex: 1 }}>
            <IconButton
              aria-label="menu-icon"
              id="menu-icon"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              PaperProps={{
                sx: {
                  border: '1px solid #D3D3D3',
                  filter:
                    'drop-shadow(0px 4.050473213195801px 4.050473213195801px rgba(0, 0, 0, 0.05))',
                  boxShadow: 'none',
                },
              }}
              anchorEl={anchorEl}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              id="basic-menu"
              open={open}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              onClose={handleCloseMenu}
            >
              <MenuList
                dense={true}
                sx={{ paddingTop: '0px', paddingBottom: '0px' }}
              >
                <MenuItem id="change-picture" onClick={showAvatarModal}>
                  <CameraIcon fontSize="small" />
                  <span style={{ marginLeft: '12px' }}>Change Picture</span>
                </MenuItem>
                <MenuItem
                  id="reset-password"
                  onClick={() => showPasswordModal(worker)}
                >
                  <ResetIcon fontSize="small" />
                  <span style={{ marginLeft: '10px' }}>Reset Password</span>
                </MenuItem>
                <MenuItem id="share-link" onClick={() => copyLink(worker.id)}>
                  <LinkIcon fontSize="small" />
                  <span style={{ marginLeft: '10px' }}>Share Link</span>
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        )
      }
      media={
        <div style={{ position: 'relative' }}>
          <Avatar
            border={isCertified}
            firstName={worker.user.firstName}
            rounded={true}
            size="lg"
            src={worker.avatarUrl}
          />
          {isCertified && (
            <GravyTrained
              sx={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '22px',
                height: '22px',
              }}
            />
          )}
        </div>
      }
      title={
        <Stack vertical style={{ flex: 5 }}>
          <Stack>
            <Title>
              {worker.user.firstName} {worker.user.middleName}{' '}
              {worker.user.lastName}
            </Title>
            <Badge
              label={worker.active ? 'Enabled' : 'Disabled'}
              status={worker.active ? 'theme' : 'danger'}
            />
          </Stack>
          {ratingEnabled &&
            worker.candidateStatus === CandidateStatusEnum.COMPLETED && (
              <Stack vertical gap={4}>
                {worker.jobsRatingCount > 0 && (
                  <RatingField
                    initialRating={worker.averageRating}
                    readOnly={true}
                    showNumberLabel={true}
                  />
                )}
                <Stack
                  gap={4}
                  style={{
                    borderTop: '1px solid #DFE1E6',
                    paddingTop: '10px',
                    width: 'auto',
                  }}
                >
                  <Small>Shifts Worked:</Small>
                  <Body weight={'semibold'}>{worker.jobsWorked}</Body>
                  <div style={{ width: '5px' }} />
                  <Small>Shifts Dropped:</Small>
                  <Body weight={'semibold'}>{worker.jobsDropped}</Body>
                </Stack>
              </Stack>
            )}
        </Stack>
      }
    >
      {isWorkerInProgress ? (
        <WorkerInProgress
          currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
          worker={worker}
        />
      ) : (
        <WorkerCompleted
          currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
          worker={worker}
        />
      )}
      {toastMsg && (
        <Snackbar
          action={
            <MuiIconButton
              color="inherit"
              id="close-notification"
              size="small"
              onClick={handleClose}
            >
              <CloseOutlined fontSize="small" />
            </MuiIconButton>
          }
          id="toast-msg"
          message={toastMsg}
          open={!!toastMsg}
          onClose={handleClose}
        />
      )}
    </Page>
  );
};

export default Worker;
