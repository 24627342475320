import { useFeatureValue } from '@growthbook/growthbook-react';
import {
  CheckCircle,
  MoreVert,
  RadioButtonUncheckedOutlined,
} from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import { Grid, IconButton, Stack } from '@mui/material';
import React, { useRef, useCallback } from 'react';
import { useModal } from 'react-modal-hook';
import { useNavigate } from 'react-router-dom';

import ProfileModal from '../ProfileModal';
import ProfileModalRevamp from '../ProfileModal/ProfileModalRevamp';
import { MenuItemsObject, RatingSection } from '../workerUtils';

import OptionsModal from './OptionsModal';

import { GravyTrained } from '@/assets/icons';
import Avatar from '@/components/Avatar';
import { Body } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { TableCell } from '@/elements/Table';
import Text from '@/elements/Text';
import { timesheetColors } from '@/styles/colors';
import { containsGravyCertification, workerName } from '@/util/worker';

interface Props {
  workerInfo: any;
  disable?: boolean;
  statusChip?: React.ReactNode;
  totalTime?: string | number;
  menuItems: React.ReactNode[] | undefined;
  handleEditTimesheet?: () => void;
  disableCheckbox: boolean;
  isSelecting?: boolean;
  setIsSelecting?: (isSelect: boolean) => void;
  setCheckboxIds?: (ids: any) => void;
  checkboxIds: string[];
  timeSheet?: any;
  handleAddComment?: () => void;
  isClientAdmin: boolean;
}

const WorkerMobileView = ({
  workerInfo,
  disable,
  statusChip,
  totalTime = '-',
  menuItems = [],
  handleEditTimesheet,
  disableCheckbox,
  isSelecting,
  setIsSelecting,
  checkboxIds,
  setCheckboxIds,
  timeSheet,
  handleAddComment,
  isClientAdmin,
}: Props) => {
  const name = workerName(workerInfo);
  const MENU_ITEMS = handleEditTimesheet
    ? [MenuItemsObject.editTimesheet(handleEditTimesheet), ...menuItems]
    : menuItems;
  const showRating = useFeatureValue(FEATURE_TOGGLE.WorkerRating, false);

  const [showOption, hideOptionModal] = useModal(
    () => (
      <OptionsModal
        hideModal={hideOptionModal}
        menuItems={MENU_ITEMS}
        title={name}
      />
    ),
    [MENU_ITEMS, name],
  );
  const [showProfModal, hideProfModal] = useModal(() => (
    <ProfileModal ProfileInfo={workerInfo} hideModal={hideProfModal} />
  ));
  const [showProfileRevampModal, hideProfileRevampModal] = useModal(() => (
    <ProfileModalRevamp
      ProfileInfo={workerInfo}
      hideModal={hideProfileRevampModal}
      isClientAdmin={isClientAdmin}
    />
  ));
  const navigate = useNavigate();
  const handleWorkerClick = () => {
    if (isClientAdmin && showRating) {
      showProfileRevampModal();
    }
    if (!isClientAdmin) {
      showProfileRevampModal();
    }
  };

  const holdTimer = useRef<any | null>(null);
  const checked = checkboxIds.includes(workerInfo.workerId);

  const startHold = useCallback(() => {
    if (disableCheckbox || isSelecting) return;

    holdTimer.current = setTimeout(() => {
      setCheckboxIds?.((ids) => [...ids, workerInfo.workerId]);
      setIsSelecting?.(true);
    }, 1000);
  }, [disableCheckbox, isSelecting]);

  const cancelHold = useCallback(() => {
    clearTimeout(holdTimer.current);
    holdTimer.current = null;
  }, []);

  const handleClick = () => {
    if (isSelecting && !disableCheckbox) {
      if (checked) {
        setCheckboxIds?.((ids) => ids.filter((i) => i !== workerInfo.workerId));
      } else {
        setCheckboxIds?.((ids) => [...ids, workerInfo.workerId]);
      }
    }
  };

  const isCertified = containsGravyCertification(workerInfo.activeCertificates);

  return (
    <TableCell
      style={{
        padding: '10px 10px 10px 15px',
        opacity: disable ? 0.6 : 1,
      }}
      onTouchEnd={cancelHold}
      onTouchStart={startHold}
    >
      <Grid
        container
        alignItems={'center'}
        columnSpacing={1}
        direction={'row'}
        justifyContent="center"
        rowSpacing={2}
        style={{
          backgroundColor:
            isSelecting && !disableCheckbox
              ? timesheetColors.selectBg
              : undefined,
          paddingBottom: '7px',
          borderRadius: isSelecting ? '8px' : undefined,
        }}
        onClick={handleClick}
      >
        <Grid item xs={3}>
          {isSelecting && !disableCheckbox ? (
            <div id="checkbox" style={{ left: 7, top: 3 }}>
              {checked ? (
                <CheckCircle htmlColor={timesheetColors.selected} />
              ) : (
                <RadioButtonUncheckedOutlined
                  htmlColor={timesheetColors.unSelected}
                />
              )}
            </div>
          ) : (
            <div style={{ position: 'relative' }}>
              <Avatar
                border={isCertified}
                rounded={true}
                size="lg2"
                src={workerInfo.avatarUrl}
              />
              {isCertified && (
                <GravyTrained
                  sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '20px',
                    height: '20px',
                  }}
                />
              )}
            </div>
          )}
        </Grid>
        <Grid item sx={{ whiteSpace: 'break-spaces' }} xs={4.6}>
          <Body
            css={{ lineHeight: '1 !important' }}
            weight="medium"
            onClick={!isSelecting && showRating ? handleWorkerClick : undefined}
          >
            {name}
          </Body>
          {
            <Stack
              alignItems={'center'}
              direction={'row'}
              spacing={0.6}
              sx={{ pt: '4px' }}
            >
              <StarIcon
                id="star-icon"
                sx={{ color: '#faaf00', width: '20px', height: '20px' }}
              />
              <Text size={'md'} weight={'semibold'}>
                {workerInfo.averageRating
                  ? workerInfo.averageRating.toFixed(1)
                  : '0.0'}
              </Text>
              <Text color={'lighter'}>{`(${workerInfo.jobsRatingCount})`}</Text>
            </Stack>
          }
          <div style={{ marginTop: '4px' }}>
            <Text as="span" color="lighter">
              Total Time:
            </Text>
            <Text as="span" css={{ paddingLeft: '6px' }}>
              {totalTime}
            </Text>
          </div>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: '4px',
          }}
          xs={3.6}
        >
          {statusChip}
        </Grid>
        <Grid item sx={{ paddingLeft: '0px !important' }} xs={0.7}>
          <IconButton
            disabled={!MENU_ITEMS.length}
            style={{ padding: '1px' }}
            onClick={showOption}
          >
            <MoreVert />
          </IconButton>
        </Grid>
        {showRating && (
          <Grid item xs={12}>
            <Stack direction={'row'} spacing={2}>
              <RatingSection
                handleAddComment={!isSelecting ? handleAddComment : undefined}
                isClientAdmin={isClientAdmin}
                phoneOnly={true}
                timeSheet={timeSheet}
              />
            </Stack>
          </Grid>
        )}
      </Grid>
    </TableCell>
  );
};

export default WorkerMobileView;
