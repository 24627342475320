import { useFeatureValue } from '@growthbook/growthbook-react';
import { MoreVert } from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import { Grid, IconButton, Stack } from '@mui/material';
import React from 'react';
import { useModal } from 'react-modal-hook';
import { useNavigate } from 'react-router-dom';

import ProfileModal from '../ProfileModal';
import ProfileModalRevamp from '../ProfileModal/ProfileModalRevamp';
import OptionsModal from '../WorkerMobileView/OptionsModal';

import { GravyTrained, AccountDollarIcon } from '@/assets/icons';
import Avatar from '@/components/Avatar';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import styled from '@/styles';
import { GetJobQuery, RelationshipKindEnum } from '@/types/graphql';
import { centsToCurrency } from '@/util/number';
import { containsGravyCertification, workerName } from '@/util/worker';

interface WorkerOfferRateProps {
  job?: GetJobQuery['job'];
  workerId: any;
}

const CardCriteria = styled('span', {
  fontSize: '12px',
  fontWeight: '600',
  color: '#262626',
});

const WorkerOfferRate = ({ job, workerId }: WorkerOfferRateProps) => {
  const jobOffer = job?.jobOffers?.find((x) => x.worker.id === workerId);
  if (jobOffer) {
    return (
      <Stack alignItems={'center'} flexDirection={'row'} gap={'2px'}>
        <AccountDollarIcon
          sx={{
            width: '16px',
            height: '16px',
          }}
        />
        <CardCriteria>{centsToCurrency(jobOffer.payRate)}/ hr</CardCriteria>
      </Stack>
    );
  }
  return null;
};

interface Props {
  workerInfo: any;
  workerStatusChip?: React.ReactNode;
  job?: GetJobQuery['job'];
  isClientAdmin: boolean;
  menuItems: React.ReactNode[] | undefined;
  workerStatus:
    | 'NoShow'
    | 'Dropped'
    | 'Removed'
    | 'Invited'
    | 'Declined'
    | 'Pending';
  relationshipStatus: RelationshipKindEnum | null;
  createWorkerRelationship: (
    reason: string,
    comments: string,
    kind: RelationshipKindEnum,
    triggeredFrom: string,
  ) => void;
  deleteWorkerRelationship: (
    kind: RelationshipKindEnum,
    triggeredFrom: string,
  ) => void;
}

const getBackgroundColor = (workerStatus: Props['workerStatus']) => {
  switch (workerStatus) {
    case 'NoShow':
      return 'linear-gradient(270deg, #FFEDED -78.65%, #FFF 75.74%)';
    case 'Dropped':
      return 'linear-gradient(270deg, #FFF7E1 -78.65%, #FFF 75.74%)';
    case 'Removed':
      return 'linear-gradient(270deg, #E4DEDE -78.65%, #FFF 75.74%)';
    case 'Invited':
      return 'linear-gradient(270deg, #EFFFFE -78.65%, #FFF 75.74%)';
    case 'Declined':
      return 'white';
    case 'Pending':
      return 'linear-gradient(270deg, #EFFFED -78.65%, #FFF 75.74%)';
    default:
      return 'linear-gradient(270deg, #EFFFED -78.65%, #FFF 75.74%)';
  }
};

const WorkerMobileDisableView = ({
  workerInfo,
  isClientAdmin,
  workerStatusChip,
  workerStatus,
  menuItems = [],
  job,
  relationshipStatus,
  createWorkerRelationship,
  deleteWorkerRelationship,
}: Props) => {
  const name = workerName(workerInfo);
  const MENU_ITEMS = [...menuItems];

  const showRating = useFeatureValue(FEATURE_TOGGLE.WorkerRating, false);

  const [showOption, hideOptionModal] = useModal(
    () => (
      <OptionsModal
        hideModal={hideOptionModal}
        menuItems={MENU_ITEMS}
        title={name}
      />
    ),
    [MENU_ITEMS, name],
  );

  const [showProfModal, hideProfModal] = useModal(() => (
    <ProfileModal ProfileInfo={workerInfo} hideModal={hideProfModal} />
  ));

  const [showProfileRevampModal, hideProfileRevampModal] = useModal(() => (
    <ProfileModalRevamp
      ProfileInfo={workerInfo}
      hideModal={hideProfileRevampModal}
      isClientAdmin={isClientAdmin}
      relationshipStatus={relationshipStatus}
      createWorkerRelationship={createWorkerRelationship}
      deleteWorkerRelationship={deleteWorkerRelationship}
    />
  ));
  const navigate = useNavigate();
  const handleWorkerClick = () => {
    if (isClientAdmin && showRating) {
      showProfileRevampModal();
    }
    if (!isClientAdmin) {
      showProfileRevampModal();
    }
  };

  const isCertified = containsGravyCertification(workerInfo.activeCertificates);

  const CardHeading = styled('h6', {
    fontSize: '14px',
    fontWeight: '600',
    color: '#262626',
    margin: '0',
    wordBreak: 'break-word',
  });

  const CardCriteria = styled('span', {
    fontSize: '12px',
    fontWeight: '600',
    color: '#262626',
  });

  const CardCriteriaTotal = styled('span', {
    fontSize: '12px',
    fontWeight: '400',
    color: '#7A7A7A',
  });

  return (
    <>
      <Stack
        style={{
          width: '100%',
        }}
      >
        {/* Note: Header Wrap Start */}
        <Stack
          alignItems={'flex-start'}
          borderBottom={
            workerStatus === 'NoShow' ||
            workerStatus === 'Dropped' ||
            workerStatus === 'Removed' ||
            workerStatus === 'Invited' ||
            workerStatus === 'Declined'
              ? 'none'
              : '1px solid rgba(121, 192, 167, 0.90)'
          }
          borderRadius={
            workerStatus === 'NoShow' ||
            workerStatus === 'Dropped' ||
            workerStatus === 'Removed' ||
            workerStatus === 'Invited' ||
            workerStatus === 'Declined'
              ? '12px'
              : '12px 12px 0px 0px'
          }
          flexDirection="row"
          gap={'6px'}
          padding="13px"
          style={{
            background: getBackgroundColor(workerStatus),
          }}
        >
          {/* Note: Avatar Wrap Start */}
          <Stack
            style={{
              opacity:
                workerStatus === 'NoShow' || workerStatus === 'Invited'
                  ? '1'
                  : '0.6',
            }}
          >
            <Grid item xs={3}>
              {
                <div style={{ position: 'relative' }}>
                  <Avatar
                    border={isCertified}
                    rounded={true}
                    size="m"
                    src={workerInfo.avatarUrl}
                  />
                  {isCertified && (
                    <GravyTrained
                      sx={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        width: '15px',
                        height: '15px',
                      }}
                    />
                  )}
                </div>
              }
            </Grid>
          </Stack>

          <Stack
            alignItems={'flex-start'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            width={'100%'}
          >
            <Stack
              flexDirection={'column'}
              gap={'2px'}
              style={{
                opacity:
                  workerStatus === 'NoShow' || workerStatus === 'Invited'
                    ? '1'
                    : '0.6',
              }}
            >
              {/* Note: Name Start */}
              <CardHeading onClick={handleWorkerClick}>{name}</CardHeading>
              {/* Note: Name Start */}

              {/* Note: Rating Start */}
              <Stack alignItems={'center'} flexDirection={'row'} gap={'8px'}>
                {
                  <Stack alignItems={'center'} direction={'row'} gap={'2px'}>
                    <StarIcon
                      id="star-icon"
                      sx={{
                        color: '#faaf00',
                        width: '16px',
                        height: '16px',
                      }}
                    />
                    <CardCriteria>
                      {workerInfo.averageRating
                        ? workerInfo.averageRating.toFixed(1)
                        : '0.0'}
                    </CardCriteria>

                    <CardCriteriaTotal>{`(${workerInfo.jobsRatingCount})`}</CardCriteriaTotal>
                  </Stack>
                }

                {!isClientAdmin && workerStatus === 'Invited' ? (
                  <WorkerOfferRate job={job} workerId={workerInfo.id} />
                ) : null}
              </Stack>
              {/* Note: Rating End */}
            </Stack>

            <Stack alignItems={'flex-start'} flexDirection={'row'}>
              {workerStatusChip}

              {MENU_ITEMS.length > 0 && (
                <IconButton
                  disabled={false}
                  style={{ padding: '1px' }}
                  onClick={showOption}
                >
                  <MoreVert />
                </IconButton>
              )}
            </Stack>
          </Stack>
          {/* Note: Rate Details Start */}
        </Stack>
        {/* Note: Header Wrap End */}
      </Stack>
    </>
  );
};

export default WorkerMobileDisableView;
