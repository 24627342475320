import {
  faCalendarTimes,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useModal } from 'react-modal-hook';

import useChangeLogoModal from '../useChangeLogoModal';

import ProgressCustomerSection, {
  customersHasPaymentMethod,
} from './ProgressCustomerSection';
import UpdateClientModal from './UpdateCustomerModal';
import { IconProgressCustomerProfile } from './styles';

import Avatar from '@/components/Avatar';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import Icon from '@/elements/Icon';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';
import { GetCustomerQuery } from '@/types/graphql';
import { capitalizeFirstLetter } from '@/util/text';

type Props = {
  customer: GetCustomerQuery['customer'];
  currentAdminIsCustomerAdmin: boolean;
  accordion?: boolean;
  accordionDefault?: boolean;
};

const SettingTitle = styled('p', {
  fontWeight: 700,
  color: '#1E2D4D',
  fontSize: '0.75rem',
  flex: 1,
});

const SettingValue = styled('p', {
  fontWeight: 400,
  color: '#332F2D',
  fontSize: '0.75rem',
});

const SettingsCard = ({
  customer,
  currentAdminIsCustomerAdmin,
  accordion = false,
  accordionDefault = false,
}: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const showLogoModal = useChangeLogoModal(customer);

  const configurableCheckinFeature = useFeatureValue(
    FEATURE_TOGGLE.ConfigurableCheckinFeature,
    false,
  );

  const [showUpdateModal, hideRenameModal] = useModal(
    () => <UpdateClientModal customer={customer} hideModal={hideRenameModal} />,
    [customer],
  );

  const { admins, accounts, addresses } = customer;
  const showProgressCustomerSection =
    admins.items.length === 0 ||
    accounts.length === 0 ||
    addresses.length === 0 ||
    customersHasPaymentMethod(accounts) === 0;

  return (
    <Card
      accordion={accordion}
      actions={
        !currentAdminIsCustomerAdmin
          ? [
              {
                a11yLabel: 'Update this customer',
                label: 'Change',
                onAction: showUpdateModal,
                id: 'btn-change-settings',
              },
            ]
          : undefined
      }
      open={accordionDefault}
      title={currentAdminIsCustomerAdmin ? 'Company' : 'Account Settings'}
    >
      {!phoneOnly && (
        <Card.Section>
          <Stack gap={20}>
            <div style={{ cursor: 'pointer' }} onClick={showLogoModal}>
              <Avatar
                a11yLabel={`Image of ${customer.name}`}
                firstName={customer.name}
                size="md"
                src={customer.logoUrl}
              />
            </div>
            <Body>{customer.name}</Body>
          </Stack>
        </Card.Section>
      )}
      {!currentAdminIsCustomerAdmin && (
        <>
          <Stack gap={0} justify="apart" style={{ padding: '10px 20px' }}>
            <SettingTitle>NET TERMS</SettingTitle>
            <Body color="iconColor">
              <Icon fixedWidth icon={faCalendarTimes} />
            </Body>
            <SettingValue>{customer.invoiceDueDate} days</SettingValue>
          </Stack>

          <Stack gap={0} justify="apart" style={{ padding: '10px 20px' }}>
            <SettingTitle>LTA</SettingTitle>
            <SettingValue>
              {customer?.ltaAllowed ? 'Enabled' : 'Disabled'}
            </SettingValue>
          </Stack>

          <Stack gap={0} justify="apart" style={{ padding: '10px 20px' }}>
            <SettingTitle>TAX TYPE</SettingTitle>
            <SettingValue>
              {capitalizeFirstLetter(customer.jobTaxType ?? 'All')}
            </SettingValue>
          </Stack>
          {
            <Stack gap={0} justify="apart" style={{ padding: '10px 20px' }}>
              <SettingTitle>MARKUP</SettingTitle>
              <SettingValue>
                {`${customer.markupPercent}%` ?? '60%'}
              </SettingValue>
            </Stack>
          }
          {configurableCheckinFeature && (
            <Stack gap={0} justify="apart" style={{ padding: '10px 20px' }}>
              <SettingTitle>CHECK IN RADIUS</SettingTitle>
              <SettingValue>
                {`${customer.checkInRadiusFeet.toLocaleString()} ft`}
              </SettingValue>
            </Stack>
          )}
        </>
      )}

      {showProgressCustomerSection ? (
        <ProgressCustomerSection customer={customer} />
      ) : (
        <Card.Section>
          <Stack>
            <IconProgressCustomerProfile completed icon={faCheckCircle} />
            <Body color="theme">Your profile is complete!</Body>
          </Stack>
        </Card.Section>
      )}
    </Card>
  );
};

export default SettingsCard;
